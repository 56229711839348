<template>
  <div class="max-w-2xl px-4 py-16 mx-auto sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8" v-if="vendors">
    <div class="flex flex-row lg:space-x-10">
      <div class="hidden max-w-2xl md:block">
        <p class="leading-10 ">Vendors</p>
        <div class="overflow-auto border rounded-md h-72" id="journal-scroll">
         
            <ul class="" v-for="(vendor) in vendors" :key="vendor.id">
              <router-link :to="{ name: 'Vendors', params: { id: vendor.id, slug: sanitizeTitle(vendor.name) }}" @click="getVendorProducts(vendor)" :class="vendor.id == 1 ? `bg-gray-400` :`cursor-pointer focus:bg-gray-400`">
                <li class="flex justify-between w-full p-4 space-x-10 text-black border-b hover:bg-gray-100">
                  <img  v-if="vendor" v-bind:src="vendor.feature_image" class="w-6 h-6 transition rounded-md">
                  <p class="w-32 text-sm text-left truncate">{{ vendor.name }}</p>
                  <ChevronRightIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
                </li>
              </router-link>
            </ul>
          
        </div>
      </div>
      <!-- vendorproducts -->
      <div class="hidden md:block" v-if="vendorProducts">
            <!-- <p class="leading-10 ">{{ vendorProducts.menu.products.length }} Items Found</p> -->
        <div class="">
            <div v-for="men in vendorProducts.menus" :key="men.id">
              <p class="mt-10 mb-5 text-4xl font-light">{{ men.name }}</p>
              <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
                <div class="relative p-3 rounded-md shadow-md group" v-for="product in men.products" :key="product.id">
                  <router-link :to="{ name: 'Product', params: { id: product.id, slug: sanitizeTitle(product.name) }}" >
                    <div class="w-full overflow-hidden rounded-lg aspect-w-1 aspect-h-1 xl:aspect-w-7 xl:aspect-h-8">
                        <img  v-if="product.photo" v-bind:src="product.photo" class="object-contain object-center w-full h-full md:h-56 group-hover:opacity-75">
                    </div>
                    <div class="flex flex-col mt-2 mb-2">
                        
                        <span v-if="product.discount_price > 0">
                            <p class="text-sm font-semibold text-gray-900">{{ currency }} <span class="line-through">{{ product.price.toFixed(1) }}</span> <span class="text-base">{{ product.discount_price.toFixed(1) }}</span></p>
                        </span>
                        <span v-else>
                            <p class="text-sm font-semibold text-gray-900">{{ currency }}{{ product.price.toFixed(2) }}</p>
                        </span>
                        <!-- <span aria-hidden="true" class="absolute inset-0"></span> -->
                        <p class="mt-1 text-sm text-gray-700 truncate">{{ product.name }}</p>
                        <!-- <div class="flex mt-5"> -->
                        <!-- <img v-if="product" :src="product.vendor.feature_image" class="object-cover w-4 h-4 rounded-md" alt=""> -->
                        <!-- <p class="w-24 p-1 text-xs text-gray-700 truncate bg-gray-100 rounded-md">{{ product.vendor.name }}</p> -->
                        <!-- </div> -->
                    </div>
                  </router-link>
                </div>
              </div>
              
            </div>
            <div v-if="vendorProducts.menus.length == '0'" class="flex items-center justify-center">
              <p class="text-center text-gray-800 ">No item(s) for this vendor</p>
            </div>
        </div>
      </div>
      <span class="hidden md:block" v-else-if="products">
        <p class="leading-10 ">{{ products.length }} Items Found</p>
        <div class="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          <router-link :to="{ name: 'Product', params: { id: product.id, slug: sanitizeTitle(product.name) }}"  v-for="product in products" :key="product.id" class="p-2 rounded shadow group">
            <div class="w-full overflow-hidden rounded-lg aspect-w-1 aspect-h-1 xl:aspect-w-7 xl:aspect-h-8">
              <img v-if="product.photo" v-bind:src="product.photo" class="object-contain object-center w-full h-full md:h-56 group-hover:opacity-75" />
            </div>
            <h3 class="mt-4 text-sm text-gray-700">
              {{ product.name }}
            </h3>
            <p class="mt-1 text-lg font-medium text-gray-900 ">
              <span v-if="product.discount_price > 0">
                <p class="text-lg font-semibold text-gray-900 ">{{ currency }} <span class="line-through">{{ product.price.toFixed(1) }}</span> <span class="text-base">{{ product.discount_price.toFixed(1) }}</span></p>
              </span>
              <span v-else>
                <p class="text-lg font-semibold text-gray-900 ">{{ currency }}{{ product.price.toFixed(2) }}</p>
              </span>
            </p>
          </router-link>
        </div>
      </span>
      <div class="md:hidden">
          <div class="grid gap-y-10 sm:grid-cols-2 gap-x-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 xl:gap-x-8" >
              <a :href="`vendor/${vendor.id}/`+sanitizeTitle(`${vendor.name}`)"  v-for="vendor in vendors" :key="vendor.id" class="rounded-md shadow">
                  <img  v-if="vendor" v-bind:src="vendor.feature_image" class="transition duration-500 ease-in-out transform shadow-sm w-72 md:w-full rounded-t-md hover:-translate-y-1 hover:scale-100">
                  <div class="p-3 md:w-full w-72 rounded-b-md bg-gray-50">
                      <p class="mt-2 mb-1 font-semibold truncate">{{ vendor.name }}</p>
                      <div class="flex flex-row ">
                      <p class="flex py-1">
                          <span class="font-light " v-for="star in vendor.rating" :key="star.id">
                              <starIcon {{ star }} class="self-center w-3 h-3 text-yellow-400 md:mt-0 md:w-6 md:h-6" aria-hidden="true" />
                          </span>
                      </p>
                      </div>
                      <p class="text-xs font-light text-gray-700 truncate">{{ vendor.address}}</p>
                  </div>
              </a>   
          </div>
      </div>
      <!-- <span v-if="products == null">
        <p class="text-gray-900">No products available</p>
      </span> -->
    </div>
  </div>
  <Download/> 
</template>

<script>
import axios from 'axios'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { StarIcon } from '@heroicons/vue/solid'
import Download from '@/components/Downloads.vue'
export default {
  name: 'Vendors',
  components: {
    StarIcon,
    ChevronRightIcon,
    Download,
  },
  
  data () {
    return {
      vendors: null,
      settings: null,
      base_url: this.$store.state.baseUrl,
      products: null,
      currency: null,
      vendorProducts: null,
      active: true,
      selectedVendor: null
    }
  },
  
  mounted () {
    this.getCurrency();
    
    axios.get(this.base_url+'api/vendors')
    .then((response) => {
      this.vendors = response.data.data
      console.log(this.vendors)
      }
    )
    .catch(error => console.log(error))

    this.$store.commit('loading', true)
    axios.get(this.base_url+'api/products')
    .then((response) => {
      this.$store.commit('loading', false)
      this.products = response.data.data
      }
    )
    .catch(error => console.log(error))

  },

  // computed: {
  //   getVendorProduct()
  //   {
  //       this.$store.commit('loading', true)
  //       axios.get(this.base_url+'api/vendors/' + this.$route.params.id)
  //       .then((response) => {
  //            this.$store.commit('loading', false)
  //            let prodts = response.data
  //            console.log(prodts)
  //           this.vendorProducts = prodts
  //       })
  //       .catch(error => console.log(error))
  //   },
  // },

  methods: {
    getCurrency() {
      let setting;
      axios.get(this.base_url+'api/app/settings')
      .then( (response) => {
          setting = response.data;
          this.currency = setting.strings.currency
      })
      return this.currency;
    }, 

    getVendorProducts(vendorData)
    {
      this.selectedVendor = vendorData
      // console.log(vendorData.id)
      let vendorId = vendorData.id
      let vendorName = vendorData.name
      // console.log(vendorId)
      this.$store.commit('loading', true)
      axios.get(this.base_url+'api/vendors/'+vendorId)
      .then((response) => {
          this.$store.commit('loading', false)
          this.vendorProducts = response.data
          console.log(this.vendorProducts)
          this.$router.push({name: 'Vendors', params: { id: vendorId, slug: this.sanitizeTitle(`${vendorName}`) }})
          // this.$router.push('/vendors/'+vendorId+'/'+vendorName)
          // window.location.href = '/vendors/'+vendorId+'/'+vendorName
          //  console.log(prodts.menus)
          // console.log(this.vendorProducts.menus[0].products)
      })
      .catch(error => console.log(error))
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      
      return slug;
    },

    
  },

}
</script>
<style>
  #journal-scroll::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

  }
  #journal-scroll::-webkit-scrollbar-track {
      background-color: rgba(229, 231, 235, var(--bg-opacity));
      cursor: pointer;
      /*background: red;*/
  }
  #journal-scroll::-webkit-scrollbar-thumb {
      cursor: pointer;
      background-color: #a0aec0;
      /*outline: 1px solid slategrey;*/
  }
</style>